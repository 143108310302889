import * as React from "react"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from "@material-ui/core"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from "gatsby";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: theme.palette.secondary.main,
        },
        textBox: {
            padding: '50px',
        },
        subtitle: {
            color: "#8690a0",
            fontSize: "26px",
            fontWeight: 600,
            margin: "-20px 0 20px 0",
        },
        link: {
            color: theme.palette.primary.main,
            textDecoration: 'underline',
            fontWeight: 'bold'
        }
    }),
);

interface Props {
    image?: IGatsbyImageData;
    title: string;
    subtitle?: string;
    text: string;
    secondaryText?: string | HTMLElement;
    link?: {
        link: string;
        text: string;
    }
    locale?:string;
}

const RowTrimed: React.FC<Props> = ({ image, title, text, secondaryText, subtitle, link, locale }) => {
    const classes = useStyles();

    return (
        <Grid container spacing={0} className={classes.root}>
            <Grid item xs={12} md={6}>
                <GatsbyImage image={image} alt="img" />
            </Grid>
            <Grid item xs={12} md={6} className={classes.textBox}>
                <Typography variant="h3" gutterBottom>
                    {title}
                </Typography>
                <div className={classes.subtitle}>
                    {subtitle}
                </div>
                <Typography variant="body1" gutterBottom>
                    {text}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {secondaryText} {link && <Link to={`/${locale}/${link.link}`} className={classes.link}>{link.text}</Link>}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default RowTrimed
