import * as React from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";

interface Props {
    gatsbyImage?: IGatsbyImageData;
    publicURL?: string;
    title: string;
    description: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        },
        icon: {
            minHeight: '120px',
            paddingBottom: '50px',
        },
        img: {
            maxWidth: '90px',
            maxHeight: '90px',
        },
        title: {
            minHeight: '120px',
        },
        description: {
            minHeight: '120px',
        },
    }),
);

const CardBasic: React.FC<Props> = ({ gatsbyImage, publicURL, title, description }) => {
    const classes = useStyles();

    return <div className={classes.root}>
        <div className={classes.icon}>
            {publicURL ? <img src={publicURL} alt="icon" className={classes.img} /> : <GatsbyImage image={gatsbyImage} alt="img" />}
        </div>
        <div>
            <div className={classes.title}>
                <Typography variant="h3" gutterBottom>
                    {title}
                </Typography>
            </div>
            <div className={classes.description}>
                <Typography variant="body1" gutterBottom>
                    {description}
                </Typography>
            </div>
        </div>
    </div>
}

export default CardBasic
