import * as React from "react"
import { graphql, PageProps } from "gatsby"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import Layout from "../components/Layout"
import { Grid, Typography } from "@material-ui/core";
import Section from "../components/Section";
import Headline from "../components/Headline";
import theme from "../gatsby-theme-material-ui-top-layout/theme";
import RowTrimed from "../components/RowTrimmed";
import CardBasic from "../components/CardBasic";
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "../components/seo";
import { FooterProps } from "../components/Footer";
import { NavigationProps } from "../components/Header/navigation";
import markdownToTxt from 'markdown-to-txt';
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxes: {
            paddingBottom: '100px',
        },
        box: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%',
        },
        boxImage: {
            margin: '50px',
            width: '300px',
        }
    }),
);

type DataProps = {
    strapiAboutUs: any
    strapiNavigation: NavigationProps
    strapiFooter: FooterProps
}

const AboutUs: React.FC<PageProps<DataProps>> = ({ data }) => {
    const classes = useStyles();
    const navigation = data.strapiNavigation;
    const footer = data.strapiFooter;
    const aboutUs = data.strapiAboutUs;
    const headline = aboutUs.headline;
    const secondaryHeadline = aboutUs.SecondaryHeadline;
    const basicCardsTitle = aboutUs.basicCardsTitle;
    const basicCards = aboutUs.basicCards;
    const rowTrimmed = aboutUs.rowTrimmed;
    const rowTrimmedTitle = aboutUs.rowTrimmedTitle;
    const boxesHeadline = aboutUs.boxesHeadline;
    const boxes = aboutUs.boxes;
    const locale = aboutUs.locale;

    return (
        <Layout navigation={navigation} footer={footer}>
            <Seo
                title={
                    `${headline.titleBefore ? headline.titleBefore : ''} ${headline.highlighted ? headline.highlighted : ''} ${headline.titleAfter ? headline.titleAfter : ''}`
                }
                description={<ReactMarkdown linkTarget="_blank" >{headline.text}</ReactMarkdown>}
            />
            <Section color="white">
                <Headline
                    titleBefore={headline.titleBefore}
                    highlighted={headline.highlighted}
                    titleAfter={headline.titleAfter}
                    text={headline.text}
                />
            </Section>
            <Section color={theme.palette.secondary.main}>
                <Headline
                    titleBefore={secondaryHeadline.titleBefore}
                    highlighted=""
                    text={secondaryHeadline.text}
                />
            </Section>
            <Section color="white">
                <Typography variant="h2" align="center">
                    {basicCardsTitle}
                </Typography>
                <Grid container spacing={4}>
                    {basicCards.map(card => <Grid item xs={6} sm={4}>
                        <CardBasic
                            key={card.id}
                            publicURL={card.icon && card.icon.localFile.publicURL}
                            title={card.title}
                            description={card.description}
                        />
                    </Grid>)}
                </Grid>
                <Typography variant="h2" align="center">
                    {rowTrimmedTitle}
                </Typography>
                <RowTrimed
                    image={rowTrimmed.image.localFile.childImageSharp.gatsbyImageData}
                    title={rowTrimmed.title}
                    subtitle={rowTrimmed.subtitle}
                    text={rowTrimmed.text}
                    secondaryText={rowTrimmed.secondaryText}
                    link={rowTrimmed.link}
                    locale={locale}
                />
                <Typography variant="h2" align="center">
                    {boxesHeadline}
                </Typography>

                <Grid container spacing={4} className={classes.boxes}>
                    {boxes && boxes.map(box => {
                        const imageUrl = box.image.localFile && box.image.localFile.childImageSharp.gatsbyImageData;

                        return <Grid item xs={12} md={6}>
                            <div className={classes.box}>
                                <GatsbyImage image={imageUrl} alt=" " className={classes.boxImage} />
                                <Typography variant="body1">{box.text}</Typography>
                            </div>

                        </Grid>
                    })}
                </Grid>
            </Section>
        </Layout >
    );
}

export default AboutUs


export const query = graphql`
query AboutUsPage($locale: String) {
    strapiAboutUs(locale: {eq: $locale}) {
        locale
        SecondaryHeadline {
            text
            titleBefore
        }
        basicCardsTitle
        basicCards {
            icon {
            localFile {
                publicURL
            }
            }
            title
        }
        boxes {
            text
            image {
            localFile {
                childImageSharp {
                gatsbyImageData
                }
            }
            }
        }
        boxesHeadline
        headline {
            highlighted
            id
            text
            titleAfter
            titleBefore
        }
        rowTrimmedTitle
        rowTrimmed {
            secondaryText
            text
            title
            subtitle
            image {
            localFile {
                childImageSharp {
                gatsbyImageData
                }
            }
            }
            link {
                link
                text
            }
        }
    }
    strapiNavigation(locale: {eq: $locale}) {
        locale
        navigationItem {
            title
            customLink
            NavigationSubItems {
                title
                sub_page {
                    slug
                    pageTitle
                }
                slug
            }
        }
        ContactUsButton {
            link
            text
        }
    }
    strapiFooter(locale: {eq: $locale}) {
      bottomLinks {
        id
        link
        text
      }
      locale
      columns {
          FooterColumnItem {
              text
              link
              anchor
          }
          Title
      }
      contactsSectionLinks {
          link
          text
      }
      city
      state
      zipCode
      email
      id
      identification
      mobilePhone
      street
      socialMedia {
          name
          image {
              localFile {
                  publicURL
              }
          }
          link
      }
      bottomText
      bottomHeadlineSection {
      mainLink {
        link
        text
      }
          inputButtonText
          highlighted
          inputLabel
          titleAfter
          titleBefore
      }
    }
}
`;

